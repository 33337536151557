import React, { lazy, useEffect, useRef, useState, Suspense } from 'react'
import ReactGA from 'react-ga4'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import LazyLoad from 'react-lazyload'
import { MdPeople } from 'react-icons/md'
import { Link } from 'react-router-dom'

import buildBook from '../../assets/images/build-book.jpg'
import communications from '../../assets/images/communications.jpg'
import guestAccommodations from '../../assets/images/guest-accommodations.jpg'
import chartLeft from '../../assets/images/donut-chart-left.svg'
import chartRight from '../../assets/images/donut-chart-right.svg'
import { CakeIcon } from '../../components/customIcons'
import { checkEnv, IframeYoutube, useOnScreen } from '../../common'

import { Benefit, Testimony } from './components'

import '../../styles/home.sass'

const InstagramPosts = React.lazy(() => import('./components/InstagramPosts'))
const TopSearches = React.lazy(() => import('./components/TopSearches'))

export const PublicHome: React.FC = () => {
  const intersectTarget = useRef<HTMLDivElement>(null)
  const [registerModal, openRegisterModal] = useState(false)
  const intersected = useOnScreen(intersectTarget)
  const [shouldLoadModal, setShouldLoadModal] = useState(false)
  const { prod } = checkEnv() // just to not play the video in dev

  useEffect(() => {
    if (!sessionStorage.getItem('reached') && !intersected) {
      sessionStorage.setItem('reached', 'reached')
      ReactGA.event({
        category: 'Scroll',
        action: 'The user reached the "makeout sessions" section',
      })
    }
  }, [intersected])

  const signupGA = (label: string): void => {
    openRegisterModal(true)
    setShouldLoadModal(true)
    ReactGA.event({
      category: 'Sign Up',
      label,
      action: 'User clicked the CTA button on the homepage',
    })
  }

  const ConversionlyModal = lazy(
    () => import('../../components/SigninSignup/ConversionlyModal')
  )

  return (
    <section className="public-container public-home">
      {/** this is to lazy load the modal component only when the button is clicked */}
      {shouldLoadModal && (
        <Suspense fallback={<></>}>
          <ConversionlyModal
            open={registerModal}
            onClose={() => openRegisterModal(false)}
          />
        </Suspense>
      )}

      <section className="welcome">
        <div className="welcome-left">
          <h1 className="font-lf">Destination Weddings Made Simple</h1>
          <h2>Kiss the headache of group gatherings goodbye!</h2>
          <Button
            onClick={() => signupGA('Main Banner')}
            className="fwsb my-1 my-md-3"
          >
            Start Planning
          </Button>
        </div>
        <div className="welcome-right">
          <IframeYoutube
            videoIds={[
              'AH-rzPp7hns',
              '-vh4tqzXnU8',
              'b3rWj-hmUHc',
              'rNzPYUzQ4Vs',
              'g6UnhZf6Kdo',
              'MaaGeTOUjQA',
            ]}
            width="268px"
            height="476px"
            type="vertical"
            controls={false}
            playing={prod}
            mobileFrame
            customVolumeControl
            muted
          />
        </div>
      </section>
      <section className="benefits py-md-4">
        <h2 className="px-3">
          Free wedding concierge to get everyone there without the headache
        </h2>
        <span className="line" />
        <Row className="my-4">
          <Col xs={12} sm={4}>
            <Benefit data={0} />
          </Col>
          <Col xs={12} sm={4}>
            <Benefit data={1} />
          </Col>
          <Col xs={12} sm={4}>
            <Benefit data={2} />
          </Col>
        </Row>
        <Suspense fallback={<div className="text-center py-3">Loading...</div>}>
          <TopSearches />
        </Suspense>
      </section>
      <section className="general-info my-5 build-and-book-your-event">
        <div className="info m-auto">
          <h2 className="mb-3">Build and Book Your Event</h2>
          <p>
            Compare, build and book event venue and group accommodations
            packages in top global destinations.
          </p>
          <p>
            Headache free automated group deal flow for hosts and vendors,
            guaranteeing more quality time spent with your guests.
          </p>
          <Link to="/marketplace">
            <Button className="fwsb" style={{ width: '205px' }}>
              Search venues
            </Button>
          </Link>
        </div>
        <LazyLoad height={300} once>
          <Image
            src={buildBook}
            alt="Build and Book Your Event"
            fluid
            className="w99"
          />
        </LazyLoad>
      </section>
      <Testimony data={0} />
      <section className="general-info my-5 revolutionary-group">
        <LazyLoad height={300} once>
          <Image src={communications} alt="Build and Book Your Event" fluid />
        </LazyLoad>
        <div className="info m-auto">
          <h2 className="mb-3">Revolutionary Group Communications</h2>
          <p>
            Connect and inform the right guest at the right time. Whether
            managing a master guest list or subgroups, guests receive their
            relevant notifications in one convenient feed.
          </p>
          <Button
            onClick={() => signupGA('Revolutionary Group Communications')}
            className="fwsb"
            style={{ width: '250px' }}
          >
            Start the conversation
          </Button>
        </div>
      </section>
      <Testimony data={1} />
      <section className="general-info my-5 guest-list-group-travel">
        <div className="info m-auto">
          <h2 className="mb-3">Guest List & Group Travel Management</h2>
          <p>
            Invite guests, segment communications by subgroups, and update as
            they book, matching them to relevent communications.
          </p>
          <p>
            Our social booking and attendance management experience allows
            guests to RSVP, select and book their own rooms, pair with roommates
            and communicate room occupants while all details are shared with
            host and vendor.
          </p>
          <Button
            onClick={() => signupGA('Guest List & Group Travel Management')}
            className="fwsb"
            style={{ width: '230px' }}
          >
            Build your guest list
          </Button>
        </div>
        <LazyLoad height={300} once>
          <Image
            src={guestAccommodations}
            alt="Build and Book Your Event"
            fluid
          />
        </LazyLoad>
      </section>
      <Testimony data={2} />
      <Container as="section" fluid className="charts mt-4 mt-md-0">
        <Row>
          <Col className="wedding" xs={12} sm={6}>
            <CakeIcon width="30px" height="33px" />
            <h2>
              We <i className="text-primary">solve the pain points </i> that
              cause couples to give up on a destination wedding
            </h2>
            <img src={chartLeft} alt="Wedding chart" className="my-4" />
            <p>
              <b className="text-primary">40%</b> of couples consider a
              destination wedding
            </p>
            <p>
              <b className="text-primary">21%</b> actually book{' '}
            </p>
            <p>
              <b className="text-primary">19%</b> throw in the towel
            </p>
            <p className="pt-4 pb-2">
              Attendees of destination weddings are twice as likely to return!
            </p>
          </Col>
          <Col className="people" xs={12} sm={6}>
            <MdPeople size={35} color="#FF6E78" />
            <h2>
              We offer guests what they need, plus give them a voice in the
              conversation
            </h2>
            <img src={chartRight} alt="Wedding chart" className="my-4" />
            <p>
              <span className="text-primary">92%</span> of guests ignore
              personal wedding website fluff and one sided communications, only
              accessing event details, accommodations links or gift registries.
            </p>
          </Col>
        </Row>
      </Container>

      <Suspense
        fallback={<div className="text-center py-3">Loading instagram...</div>}
      >
        <InstagramPosts />
      </Suspense>

      <Container
        fluid
        className="makeout-sessions-card p-3 p-md-5 mb-3"
        ref={intersectTarget}
      >
        <h2 className="fwb w-100 text-center fz-35">Kiss & Tell Demo</h2>
        <span className="line bg-secondary my-3" />
        <h4 className="mb-5 w-100 text-center fz-25">
          Destination wedding booking and group chat
        </h4>
        <div className="w-100 mx-auto" style={{ maxWidth: '889px' }}>
          <IframeYoutube
            videoId="jAJuzPinbPo"
            height="500px"
            muted
            playing={false}
          />
        </div>
      </Container>
    </section>
  )
}
