import React, { useState, Suspense } from 'react'
import get from 'lodash/fp/get'
import map from 'lodash/fp/map'
import flow from 'lodash/fp/flow'
import filter from 'lodash/fp/filter'
import flattenDepth from 'lodash/fp/flattenDepth'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { Helmet } from 'react-helmet'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router'
import { MdPeople, MdFilterList } from 'react-icons/md'

import Tiers from '../../../components/Tiers'
import { GET_GUESTLIST_WITH_BOOKINGS } from '../../../graphql'
import { SimpleLoader } from '../../../components'
import { Common404 } from '../../../components/Error404'
import {
  Guest,
  MyEvent,
  Booking,
  BookingStatus,
  EventComponent,
} from '../../../types'

import { TiersModal, AddEditGuest } from './components'
import { GuestListTitle } from './components/style'

const BannersModule = React.lazy(
  () => import('../../../components/BannersModule/BannersModule')
)
const GuestsGrid = React.lazy(() => import('./components/GuestsGrid'))

export const GuestList: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>()
  const [manageGuest, setManageGuest] = useState(false)
  const [currentGuest, setCurrentGuest] = useState<Guest>()
  const [selectedTiers, updateSelectedTiers] = useState<string[]>([])
  const [showTiersModal, setShowTiersModal] = useState(false)

  const variables = { id: eventId, tierIds: selectedTiers }
  const { data, loading } = useQuery(GET_GUESTLIST_WITH_BOOKINGS, { variables })
  const event: MyEvent = get('me.myEvents[0]', data)
  const availablePackages: EventComponent[] = get('availablePackages', data)

  if (loading) return <SimpleLoader />
  if (!event) return <Common404 text="Data not available" />

  const { Booked, Cancelled, Declined } = BookingStatus
  const allBookings = flow(
    map((guest: Guest) => {
      return [...guest.booking, ...map((g: Guest) => g?.booking, guest?.guests)]
    }),
    flattenDepth(2),
    filter((b: Booking) => [Booked, Cancelled, Declined].includes(b.status))
  )(event?.guests)

  return (
    <Container className="position-relative">
      <Helmet>
        <title>Kiss & Tell - Guest List</title>
        <meta
          name="description"
          content="Manage your guest list and their sublists. Add, edit, and remove guests as needed."
        />
      </Helmet>
      <Button
        style={{ borderRadius: '50px', top: '-35px', right: '15px' }}
        className="cursor p-1 position-absolute d-block d-sm-none"
        variant="outline-secondary"
        onClick={() => setShowTiersModal(true)}
      >
        <MdFilterList size={25} />
      </Button>

      <AddEditGuest
        event={event}
        show={manageGuest}
        onClose={() => {
          setManageGuest(false)
          setCurrentGuest(undefined)
        }}
        selectedGuest={currentGuest}
      />
      <TiersModal
        show={showTiersModal}
        closeTiersModal={setShowTiersModal}
        event={event}
        selectedTiers={selectedTiers}
        updateSelectedTiers={updateSelectedTiers}
      />
      <Row>
        <Col className="pt-3">
          <GuestListTitle className="font-lf">{event.name}</GuestListTitle>
          <Button
            style={{ zIndex: 10, position: 'relative' }}
            className="float-right fwsb btn-outline-salmon-simple"
            onClick={() => setManageGuest(true)}
          >
            <MdPeople size={20} /> Add Guest
          </Button>
        </Col>
      </Row>

      <Card className="shadow-sm mt-3">
        <div className="hide-on-mobile text-center p-4">
          <h3 className="fwb text-primary fz-18">
            Please assign sublists to all relevant guests
          </h3>
          <p className="fwsb mb-2">
            Easily filter your master list by sublists
          </p>
          <Tiers.Container
            event={event}
            selection={selectedTiers}
            onSelect={updateSelectedTiers}
          />
        </div>
        <Suspense fallback={<SimpleLoader />}>
          <GuestsGrid
            bookingList={allBookings}
            event={event}
            selectedTiers={selectedTiers}
            setCurrentGuest={setCurrentGuest}
            setManageGuest={setManageGuest}
            availablePackages={availablePackages}
          />
        </Suspense>
      </Card>

      <p className="tc-lgray w-75 mt-3 pb-3">
        *Guests with no listed email can still be tracked on the guest list and
        be added to other guest rooms, but they will not see notifications or
        updates sent through Kiss & Tell.
      </p>

      <h3 className="mt-4 pb-2 fwl fz-35 text-center">
        Share the location with your guests by selecting a venue or group stay!
      </h3>
      <Suspense fallback={<SimpleLoader />}>
        {/** In this view the user will always have events, so it's not necessary to use a query */}
        <BannersModule myEvents={1} text="Search Venues" />
      </Suspense>
    </Container>
  )
}
